'use client';

import Image, { ImageLoaderProps, ImageProps } from 'next/image';
import React, { ForwardedRef, forwardRef } from 'react';

import { scaleLayeredImage } from '~/util/scaleLayeredImage';
import { addToUrl } from '~/util/addToUrl';

export interface S7ImageProps extends ImageProps {
	disableScaleS7Image?: boolean
}

function getS7ImageLoader({
	disableScaleS7Image = false,
	height: rawHeight = 0,
}: S7ImageProps) {
	return ({
		quality = 0,
		src = '',
		width = 0,
	}: ImageLoaderProps) => {
		// the next image loader function currently only provides width and not height
		// so we supply the largest height to s7, and it still returns the correct aspect image
		const height = (typeof rawHeight === 'string') ? +(rawHeight.match(/\d/g)?.join('') || 0) : 0;
		const heightOrWidth = height >= width ? height : width;

		const srcToReturn = disableScaleS7Image ? src : scaleLayeredImage(src, width, heightOrWidth);

		if (quality) {
			return addToUrl(srcToReturn, `qlt=${quality}`);
		}

		return srcToReturn;
	};
}

export const S7Image = forwardRef((props: S7ImageProps, ref: ForwardedRef<HTMLImageElement>) => {
	const { disableScaleS7Image, ...imageProps } = props;

	return (
		<Image
			{...imageProps}
			loader={getS7ImageLoader(props)}
			ref={ref}
		/>
	);
});
